import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "my-4 space-x-2" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "grid grid-cols-10 2xl:gap-4 gap-2" }
const _hoisted_7 = { class: "col-span-4" }
const _hoisted_8 = { class: "col-span-6 relative" }
const _hoisted_9 = {
  key: 0,
  label: "Scope 1",
  name: "1",
  class: "box py-3 space-y-3 h-full"
}
const _hoisted_10 = { class: "py-4 relative w-full" }
const _hoisted_11 = {
  key: 1,
  label: "Scope 3",
  name: "3",
  class: "box py-3 space-y-3 h-full"
}
const _hoisted_12 = { class: "py-4 relative w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/setting-information" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Seting Information")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/setting-information/scope-1-and-scope-3-emissions" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Scope 1 and Scope 3 Emissions ")
          ])),
          _: 1
        })
      ]),
      _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "input-data-manual" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Input Scope 1 & Scope 3 ")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($setup.HASH_SCOPE_1_3_INPUT_MANUAL), (value) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "inline-block",
          key: value
        }, [
          _createElementVNode("button", {
            type: "button",
            class: _normalizeClass(['tab-box', $setup.route.hash === `#${value}` && 'active']),
            onClick: _withModifiers(($event: any) => ($setup.handleTabClick(value)), ["prevent"])
          }, _toDisplayString($setup.computeTabName($setup.TAB_SCOPE_1_3_INPUT_MANUAL, value)), 11, _hoisted_5)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: _normalizeClass([
            'box py-3 relative',
            ($setup.isLoadingScopeOneRequestDetail ||
              $setup.isLoadingScopeThreeRequestDetail) &&
              'isSubmitting-rounded',
          ])
        }, [
          ($setup.route.hash === `#${$setup.HASH_SCOPE_1_3_INPUT_MANUAL.SCOPE_1}`)
            ? (_openBlock(), _createBlock($setup["Scope1Form"], {
                key: 0,
                onResetFormS1OnClickRow: $setup.resetFormOnClickRowS1
              }))
            : ($setup.route.hash === `#${$setup.HASH_SCOPE_1_3_INPUT_MANUAL.SCOPE_3}`)
              ? (_openBlock(), _createBlock($setup["Scope3Form"], {
                  key: 1,
                  onResetFormS3OnClickRow: $setup.resetFormOnClickRowS3
                }))
              : _createCommentVNode("", true)
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_8, [
        ($setup.route.hash === `#${$setup.HASH_SCOPE_1_3_INPUT_MANUAL.SCOPE_1}`)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _cache[5] || (_cache[5] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold mt-5" }, " Scope 1 Emissions ", -1)),
              _createElementVNode("div", {
                class: _normalizeClass([
              'h-full ',
              $setup.isLoadingScopeOneHistory ? 'isSubmitting-rounded' : '',
            ])
              }, [
                _createElementVNode("div", _hoisted_10, [
                  (!$setup.isLoadingScopeOneHistory)
                    ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                        key: 0,
                        class: _normalizeClass(_ctx.styles['column-size']),
                        data: $setup.scopeOneHistory,
                        seriesName: "Scope 1 (CO2 Tonnes)",
                        yTitle: "Tonnes CO2",
                        colors: ['#1ccca8', '#1ccca8', '#5e5e5e'],
                        legendStyle: {
                  position: 'bottom',
                  fontSize: 16,
                  padding: [30, 0, 15, 0],
                },
                        isChartXScroll: true
                      }, null, 8, ["class", "data"]))
                    : _createCommentVNode("", true)
                ])
              ], 2)
            ]))
          : ($setup.route.hash === `#${$setup.HASH_SCOPE_1_3_INPUT_MANUAL.SCOPE_3}`)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold mt-5" }, " Scope 3 Emissions ", -1)),
                _createElementVNode("div", {
                  class: _normalizeClass([
              'h-full ',
              $setup.isLoadingScopeThreeHistory ? 'isSubmitting-rounded' : '',
            ])
                }, [
                  _createElementVNode("div", _hoisted_12, [
                    (!$setup.isLoadingScopeThreeHistory)
                      ? (_openBlock(), _createBlock($setup["ColumnChart"], {
                          key: 0,
                          class: _normalizeClass(_ctx.styles['column-size']),
                          data: $setup.scopeThreeHistory,
                          seriesName: "Scope 3(CO2 Tonnes)",
                          yTitle: "Tonnes CO2",
                          colors: ['#2b74a8', '#2b74a8', '#5e5e5e'],
                          legendStyle: {
                  position: 'bottom',
                  fontSize: 16,
                  padding: [30, 0, 15, 0],
                },
                          isChartXScroll: true
                        }, null, 8, ["class", "data"]))
                      : _createCommentVNode("", true)
                  ])
                ], 2)
              ]))
            : _createCommentVNode("", true)
      ])
    ])
  ]))
}