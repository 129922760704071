import { defineComponent as _defineComponent } from 'vue'
import { IScopeOneScopeThreeRequestDetailParamsDTO } from "@/models/ScopeOneScopeThreeEmissions";
import { computeTabName, useHash } from "@/utils/hooks";
import ColumnChart from "@/components/AnyChart/ColumnChart.vue";
import {
  HASH_SCOPE_1_3_INPUT_MANUAL,
  TAB_SCOPE_1_3_INPUT_MANUAL,
} from "@ems/constants";
import Scope1Form from "@ems/containers/GreenOptions/TheForm/Scope1.Form.vue";
import ScopeOneScopeThreeModule from "@ems/containers/GreenOptions/TheForm/Scope1Scope3.module";
import Scope3Form from "@ems/containers/GreenOptions/TheForm/Scope3.Form.vue";
import isEmpty from "lodash/isEmpty";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputDataManual',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const router = useRouter();
const scope1Form = ref();
const scope3Form = ref();

const isLoadingScopeOne = computed(
  () => ScopeOneScopeThreeModule.isLoadingScopeOneTotalGetter
);
const isLoadingScopeThree = computed(
  () => ScopeOneScopeThreeModule.isLoadingScopeThreeTotalGetter
);

const isLoadingScopeOneRequestDetail = computed(
  () => ScopeOneScopeThreeModule.isLoadingScopeOneRequestDetailGetter
);

const isLoadingScopeThreeRequestDetail = computed(
  () => ScopeOneScopeThreeModule.isLoadingScopeThreeRequestDetailGetter
);

const scopeOneHistory = computed(
  () => ScopeOneScopeThreeModule.scopeOneHistoryGetter
);

const isLoadingScopeOneHistory = computed(
  () => ScopeOneScopeThreeModule.isLoadingScopeOneHistoryGetter
);

const scopeThreeHistory = computed(
  () => ScopeOneScopeThreeModule.scopeThreeHistoryGetter
);

const isLoadingScopeThreeHistory = computed(
  () => ScopeOneScopeThreeModule.isLoadingScopeThreeHistoryGetter
);

useHash(HASH_SCOPE_1_3_INPUT_MANUAL);

const handleTabClick = (tabs: string) => {
  router.replace({ hash: `#${tabs}` });
};
const dataScopeThree = computed(
  () => ScopeOneScopeThreeModule.scopeThreeDataChartGetter
);
const dataScopeOne = computed(
  () => ScopeOneScopeThreeModule.scopeOneDataChartGetter
);
const handleRowClickS1 = async (row: any) => {
  handleResetScop1Form();
  ScopeOneScopeThreeModule.toggleS1ButtonType(true);
  const data = {
    Year: ScopeOneScopeThreeModule.currentOptionYearS1Getter,
    requestId: row.Id,
  };
  await ScopeOneScopeThreeModule.getScopeOneRequestDetailAction(
    data as IScopeOneScopeThreeRequestDetailParamsDTO
  );
};
const handleRowClickS3 = async (row: any) => {
  handleResetScop3Form();
  ScopeOneScopeThreeModule.toggleS3ButtonType(true);
  const data = {
    Year: ScopeOneScopeThreeModule.currentOptionYearS3Getter,
    requestId: row.Id,
  };
  await ScopeOneScopeThreeModule.getScopeThreeRequestDetailAction(
    data as IScopeOneScopeThreeRequestDetailParamsDTO
  );
};

const resetFormOnClickRowS1 = (form: any) => {
  scope1Form.value = form;
  return;
};

const handleResetScop1Form = () => {
  !isEmpty(scope1Form.value) &&
    scope1Form.value.resetForm({
      values: {},
    });
};

const resetFormOnClickRowS3 = (form: any) => {
  scope3Form.value = form;
  return;
};

const handleResetScop3Form = () => {
  !isEmpty(scope3Form.value) &&
    scope3Form.value.resetForm({
      values: {},
    });
};

const __returned__ = { route, router, scope1Form, scope3Form, isLoadingScopeOne, isLoadingScopeThree, isLoadingScopeOneRequestDetail, isLoadingScopeThreeRequestDetail, scopeOneHistory, isLoadingScopeOneHistory, scopeThreeHistory, isLoadingScopeThreeHistory, handleTabClick, dataScopeThree, dataScopeOne, handleRowClickS1, handleRowClickS3, resetFormOnClickRowS1, handleResetScop1Form, resetFormOnClickRowS3, handleResetScop3Form, get IScopeOneScopeThreeRequestDetailParamsDTO() { return IScopeOneScopeThreeRequestDetailParamsDTO }, get computeTabName() { return computeTabName }, get useHash() { return useHash }, ColumnChart, get HASH_SCOPE_1_3_INPUT_MANUAL() { return HASH_SCOPE_1_3_INPUT_MANUAL }, get TAB_SCOPE_1_3_INPUT_MANUAL() { return TAB_SCOPE_1_3_INPUT_MANUAL }, Scope1Form, get ScopeOneScopeThreeModule() { return ScopeOneScopeThreeModule }, Scope3Form, get isEmpty() { return isEmpty }, computed, ref, get useRoute() { return useRoute }, get useRouter() { return useRouter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})