import { IFacilityByCountry } from "@/models/Facility";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";
import { getEnv } from "@/utils/storage";

const METADATA = "Metadata";
const env = getEnv();
export class MetadataAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }
  public getRegion = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(METADATA)
      .catch(catchAxiosError);

    return response;
  };

  public getFacilityByCountry = async (
    params: IFacilityByCountry
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get("/Facilities", { params })
      .catch(catchAxiosError);

    return response;
  };
  public getAllCountry = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get("/Countries")
      .catch(catchAxiosError);

    return response;
  };

  public getAllCountryContinent = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get("/Countries/Continents")
      .catch(catchAxiosError);

    return response;
  };

  public getAllProvincesByCountryCode = async (
    countryCode: string
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`/Data/${countryCode}/provinces`)
      .catch(catchAxiosError);

    return response;
  };
}
