import { defineComponent as _defineComponent } from 'vue'
import { IUploadScopeDTO } from "@/models/FormData";
import { IScopeOneScopeThreeParameters } from "@/models/ScopeOneScopeThreeEmissions";
import AccountModule from "@/store/modules/Account";
import MetadataModule from "@/store/modules/Metadata";
import {
  buildAndFormatDataScope,
  formatNumber,
  removeNumberFormat,
  generateMonthsTotal,
  validateInputNumberType,
} from "@/utils/helpers/buildDataFormat";
import { isCSV } from "@/utils/helpers/checkFileType";
import { MONTHS_SCOPE1_SCOPE3 } from "@ems/constants";
import ScopeOneScopeThreeModule from "@ems/containers/GreenOptions/TheForm/Scope1Scope3.module";
import { ElMessage } from "element-plus/lib/components/message";
import { Field, FieldArray, Form, configure } from "vee-validate";
import { computed, onUnmounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { number, object, string } from "yup";
interface RawFile {
  name: string;
  url: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'Scope1.Form',
  emits: ["resetFormS1OnClickRow"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});
const facilityField = ref();
const countryField = ref();
const yearField = ref();
const scope1Form = ref();
const selectFormField = ref();

const months = reactive<any>({ data: MONTHS_SCOPE1_SCOPE3 });

const schema = object({
  Country: string()
    .required("Country is a required field")
    .label("Country")
    .default(""),
  FacilityId: string()
    .required("Facility is a required field")
    .label("Facility")
    .default(""),
  Year: number().required("Year is a required field").nullable(),
});

const route = useRoute();

const isReadonlyFormScope1 = computed(
  () => ScopeOneScopeThreeModule.isReadonlyFormScope1Getter
);
const scopeOneTotalGetter = computed(
  () => ScopeOneScopeThreeModule.scopeOneTotalGetter
);
const isLoadingScopeOneTotal = computed(
  () => ScopeOneScopeThreeModule.isLoadingScopeOneTotalGetter
);
const Authorization = computed(
  () => AccountModule.getUserInfo?.authorizationString + ""
);

const dataCountries = computed(() => MetadataModule.dataCountriesGetter);
const facilities = reactive<any>({ data: [] });

const request = reactive<any>({ params: {} });
const collectData = reactive<any>({
  data: {
    Country: [],
    FacilityId: "",
    Year: "",
  },
});
onUnmounted(() => {
  ScopeOneScopeThreeModule.emptyscopeOneTotal();
  ScopeOneScopeThreeModule.emptyScopeOneRequestDetail();
  ScopeOneScopeThreeModule.emptyScopeOneRequest();
  ScopeOneScopeThreeModule.emptyScopeOneHistory();
  ScopeOneScopeThreeModule.toggleS1ButtonType(false);
});
const handleChangeCountry = async (value: any) => {
  scope1Form.value.resetForm({
    values: {
      MonthValue: new Array(12).fill(""),
    },
  });
  collectData.data = {
    ...collectData.data,
    Country: value,
  };
  facilityField.value.reset();
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = facilitiesGetter.filter((fac) => fac.CountryId == value);
};

const handleChangeFacility = async (value: any) => {
  scope1Form.value.resetForm({
    values: {
      MonthValue: new Array(12).fill(""),
    },
  });
  collectData.data = {
    ...collectData.data,
    FacilityId: value,
  };
  if (collectData.data.Year) {
    request.params = {
      FacilityId: value,
      Year: collectData.data.Year,
    } as IScopeOneScopeThreeParameters;
    await ScopeOneScopeThreeModule.getScopeOneRequestAction(request.params);
    await ScopeOneScopeThreeModule.getScopeOneTotalAction(request.params);
    await ScopeOneScopeThreeModule.getScopeOneHistoryAction(request.params);
  }
};

const handleChangeYear = async (value: any) => {
  scope1Form.value.resetForm({
    values: {
      MonthValue: new Array(12).fill(""),
    },
  });
  collectData.data = {
    ...collectData.data,
    Year: value,
  };
  if (collectData.data.FacilityId) {
    ScopeOneScopeThreeModule.storageCurrentOptionYearS1(value);
    request.params = {
      FacilityId: collectData.data.FacilityId,
      Year: value,
    } as IScopeOneScopeThreeParameters;
    await ScopeOneScopeThreeModule.getScopeOneRequestAction(request.params);
    await ScopeOneScopeThreeModule.getScopeOneTotalAction(request.params);
    await ScopeOneScopeThreeModule.getScopeOneHistoryAction(request.params);
  }
};

const handleResetForm = (values: any, resetForm: any) => {
  resetForm({
    values: {
      // ...values,
      MonthValue: new Array(12).fill(""),
    },
  });
  emits("resetFormS1OnClickRow", scope1Form.value);
  ScopeOneScopeThreeModule.toggleS1ButtonType(false);
};

const beforeUploadFile = (file: any) => {
  const checkCSV = isCSV(file.name);
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!checkCSV) {
    ElMessage.error("File must be .csv, .xlsx, .xls format!");
  }
  if (!isLt2M) {
    ElMessage.error("File size can not exceed 2MB!");
  }
  return checkCSV && isLt2M;
};
//TODO
// const fileListPPA = ref<RawFile[]>([
//   {
//     name: "Document 1.pdf",
//     url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
//   },
//   {
//     name: "Document 2.pdf",
//     url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
//   },
// ]);
const onSubmit = async (values: any, actions: any) => {
  const result = await selectFormField.value.validate();
  if (!result.valid) return;
  const monthArrayValue = buildAndFormatDataScope(values);
  const requestData = {
    ...monthArrayValue,
    ...collectData.data,
  } as IUploadScopeDTO;
  await ScopeOneScopeThreeModule.uploadScopeOneAction(requestData);
  await ScopeOneScopeThreeModule.getScopeOneRequestAction(request.params);
  await ScopeOneScopeThreeModule.getScopeOneTotalAction(request.params);
  await ScopeOneScopeThreeModule.getScopeOneHistoryAction(request.params);
};
const onBlurMonthField = (value: any, setFieldValue: any, field: any) => {
  if (!value || value.length < 1) return;
  const result = formatNumber(Number(value), 0);
  setFieldValue(field, result);
};
const onFocusMonthField = (value: any, setFieldValue: any, field: any) => {
  if (!value || value.length < 1) return;
  const result = removeNumberFormat(value);
  setFieldValue(field, result);
};
const onChangeMonthField = (value: any, setFieldValue: any, field: any) => {
  const result = validateInputNumberType(parseFloat(value));
  setFieldValue(field, result);
};

const __returned__ = { emits, facilityField, countryField, yearField, scope1Form, selectFormField, months, schema, route, isReadonlyFormScope1, scopeOneTotalGetter, isLoadingScopeOneTotal, Authorization, dataCountries, facilities, request, collectData, handleChangeCountry, handleChangeFacility, handleChangeYear, handleResetForm, beforeUploadFile, onSubmit, onBlurMonthField, onFocusMonthField, onChangeMonthField, get IUploadScopeDTO() { return IUploadScopeDTO }, get IScopeOneScopeThreeParameters() { return IScopeOneScopeThreeParameters }, get AccountModule() { return AccountModule }, get MetadataModule() { return MetadataModule }, get buildAndFormatDataScope() { return buildAndFormatDataScope }, get formatNumber() { return formatNumber }, get removeNumberFormat() { return removeNumberFormat }, get generateMonthsTotal() { return generateMonthsTotal }, get validateInputNumberType() { return validateInputNumberType }, get isCSV() { return isCSV }, get MONTHS_SCOPE1_SCOPE3() { return MONTHS_SCOPE1_SCOPE3 }, get ScopeOneScopeThreeModule() { return ScopeOneScopeThreeModule }, get ElMessage() { return ElMessage }, get Field() { return Field }, get FieldArray() { return FieldArray }, get Form() { return Form }, get configure() { return configure }, computed, onUnmounted, reactive, ref, get useRoute() { return useRoute }, get number() { return number }, get object() { return object }, get string() { return string } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})