import { onMounted, onUpdated } from "vue";
import { useRoute, useRouter } from "vue-router";

export const useHash = (HASH = {} as any) => {
  const route = useRoute();
  const router = useRouter();
  const getHash = () => {
    if (
      !route.hash ||
      !Object.values(HASH).find((value) => `#${value}` === route.hash)
    ) {
      const keys = Object.keys(HASH);
      if (keys.length > 0) {
        router.replace({ hash: `#${keys[0].toLowerCase()}` });
      }
    }
  };

  onMounted(() => {
    getHash();
  });

  onUpdated(() => {
    getHash();
  });
};
export const computeTabName = (TAB_HASH = {} as any, value: string) => {
  return TAB_HASH[value.toUpperCase()];
};
